import { getAsyncError, getError } from '@services/handle-fetch-response';
import { OrderDataPOMS } from '@ts/poms/orders';

export const getOrders = async ({ id, page = null, range = null }): Promise<OrderDataPOMS> => {
	try {
		const pageNumber = page ? `?page=${page}` : '';
		const dateRange = range && page ? `?date_range=${range}` : '';
		const pomsUrl = `${process.env.NEXT_PUBLIC_POMS_URL}/api/v2/customer/${id}/orders${dateRange}${pageNumber}`;

		const res = await fetch(pomsUrl, {
			method: 'GET',
			headers: {
				'X-API-KEY': process.env.NEXT_PUBLIC_POMS_API_KEY,
			},
		});

		if(res.ok) {
			const { data: orders, pagination, errors } = await res.json();

			if (errors && errors.length) {
				throw getError(errors, res.status);
			}

			return { orders, pagination };
		}

		if(res.status === 404) {
			return null;
		}

		throw await getAsyncError(res);
	} catch (error) {
		throw error;
	}
};
