import { Children, useRef, cloneElement } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { AccountTabs, Footer, Header, LayoutStyles as styles, Toast } from '@components';
import { Head } from '@components/utils';
import { useToastContext } from '@context';

const AccountLayout = ({
	children,
	pageProps = {
		page: null,
		background: null,
		announcements: null,
		footer: null,
		header: null,
	},
}) => {
	const parentRef = useRef(null);
	const { announcements, footer, header, background, page } = pageProps;
	const router = useRouter();
	const activeTab = router.pathname.split('/').pop();
	const { toast } = useToastContext();

	const mainClasses = cn(styles['main'], styles['account'], {
		[styles['gray0']]: background === 'gray0',
		[styles['gray1']]: background === 'gray1',
		[styles['gray2']]: background === 'gray2',
		[styles['orders-id-page']]: page === 'orders-id',
	});

	return (
		<div className='accountLayoutContainer'>
			<Head />
			<Header announcements={announcements} header={header} />
			<AccountTabs defaultValue={activeTab} />
			<main className={mainClasses} ref={parentRef}>
				<div className={`pageContainer ${styles['content']}`}>
					{Children.map(children, (child) => {
						return cloneElement(child, { forwardedRef: parentRef });
					})}
				</div>
			</main>
			<Footer page={page} footer={footer} />
			<Toast {...toast} />
		</div>
	);
};

export default AccountLayout;
