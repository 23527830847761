import styles from './YotpoWidget.module.scss';

const YotpoWidget = ({ widgetId }) => {
	return (
		<div className={styles.widget}>
			<div className='yotpo-widget-instance' data-yotpo-instance-id={widgetId} />
		</div>
	);
};

export default YotpoWidget;
